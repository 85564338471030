import { h } from 'preact';
import { Route, Router } from 'preact-router';

import Header from './header/header';
import Footer from './footer/footer';

// Code-splitting is automated for `routes` directory
import HomePage from '../routes/home';
import AboutPage from '../routes/about';
import TimeTablePage from '../routes/timetable';
import NotFoundPage from '../routes/notfound';
import StationsPage from '../routes/stations';
import Tracker from './tracker/tracker';

const App = () => (
    <div id="app">
            <Tracker />
            <Router>
                <Route path="/" component={HomePage} />
                <Route path="/about/" component={AboutPage} />
                <Route path="/stations/" component={StationsPage} />
                <Route path="/timetable/:location" component={TimeTablePage} />
                <Route default component={NotFoundPage} />
            </Router>
        <Footer />
    </div>
);

export default App;
