import { h, Fragment } from 'preact';
import { useEffect } from 'preact/hooks';
declare const window: any;

const Tracker: React.FC = () => {
    useEffect(() => {
        const _mtm = (window._mtm = window._mtm || []);
        _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
        const d = document,
            g = d.createElement('script'),
            s = d.getElementsByTagName('script')[0];
        g.async = true;
        g.src = 'https://scripts.ragerworks.com/js/container_rtRcjx2h.js';
        s.parentNode.insertBefore(g, s);
    }, []);

    return <></>;
};

export default Tracker;
